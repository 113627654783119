@tailwind base;

html,
body,
#root {
  @apply h-full bg-gray-50;
}

@tailwind components;

@tailwind utilities;
